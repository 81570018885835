main {
  box-sizing: border-box;
  padding: 10vmin;
  margin: auto;
  width: 100%;
  max-width: 800px;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header header header'
    'body body body'
    '. button .';
  place-content: center;
  text-align: center;
}

header {
  grid-area: header;
  font-size: max(3rem, 6vmin);
}

p {
  grid-area: body;
  font-size: max(1.5rem, 3vmin);
  line-height: 1.4;
}

button {
  grid-area: button;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  border: none;
  border-radius: 10ex;
  cursor: pointer;
  transition: transform 0.1s;
}

button:hover {
  transform: scale(1.05);
}

button:active {
  transform: none;
}

time {
  grid-area: header;
  font-size: max(6rem, 12vmin);
  letter-spacing: 0.05em;
}

time .colon {
  display: inline-block;
  transform: translateY(-8%);
}

button.clockOut,
button.hangup {
  background: #b71c1c;
  color: white;
}

button.circle {
  --diameter: max(5rem, 10vmin);
  display: grid;
  place-content: center;
  width: var(--diameter);
  height: var(--diameter);
  font-size: calc(var(--diameter) * 0.5);
  border-radius: 50%;
  cursor: pointer;
}

button.answer {
  background: #00d42e;
  animation: 2s infinite alternate answer-box-shadow;
}

@keyframes answer-box-shadow {
  from {
    box-shadow: 0 0 0 0 #00d42e66;
  }

  to {
    box-shadow: 0 0 0.125rem 0.75rem #00d42e66;
  }
}
